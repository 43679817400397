export const paperStyle = (isMobile) => ({
  padding: '32px',
  width: isMobile ? '90%' : '400px',
  margin: isMobile ? '50px auto' : '100px auto',
  textAlign: 'center',
});

export const formStyle = {
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
};

export const backLinkStyle = {
  position: 'absolute',
  top: '20px',
  left: '20px',
  textDecoration: 'none',
  color: 'inherit',
};
