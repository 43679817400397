import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addUser, destroyUserSession, createUserSession } from '../actions/session';
import { selectUser, selectUserIsLoggedIn } from '../selectors';

function LandingPage() {
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const isLoggedIn = useSelector(selectUserIsLoggedIn);

  const handleAddUser = (email, password, passwordConfirmation) => {
    dispatch(addUser(email, password, passwordConfirmation));
  };

  const handleCreateUserSession = (email, password) => {
    dispatch(createUserSession(email, password));
  };

  const handleDestroyUserSession = () => {
    dispatch(destroyUserSession());
  };

  return (
    <div>
      <p>Welcome, {isLoggedIn ? user?.email : 'Guest'}!</p>
    </div>
  );
}

export default LandingPage;
