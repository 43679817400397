import React, { useState } from 'react';
import { BottomNavigation, BottomNavigationAction } from '@mui/material';

const Footer = () => {
  const [bottomNavValue, setBottomNavValue] = useState(0);

  return (
    <BottomNavigation
      showLabels
      value={bottomNavValue}
      onChange={(event, newValue) => setBottomNavValue(newValue)}
    >
      <BottomNavigationAction label="Home" />
      <BottomNavigationAction label="Search" />
      <BottomNavigationAction label="Settings" />
    </BottomNavigation>
  );
};

export default Footer;
