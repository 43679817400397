import React from 'react';
import { Provider } from 'react-redux';
import setupStore from './common/configureStore';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LayoutApp from './containers/LayoutApp';
import LayoutPublic from './containers/LayoutPublic';
import Home from './containers/Home';
import LandingPage from './containers/LandingPage';
import Login from './containers/Login';
import Register from './containers/Register';
import NotAuthorized from './containers/NotAuthorized';
import NewDummy from './containers/NewDummy';

const store = setupStore();

const REGISTRATION_ENABLED = process.env.REGISTRATION_ENABLED;

function App() {
  return (
    <Provider store={store}>
      <Router>
        <Routes>
          <Route path="/" element={<LayoutPublic />}>
            <Route index element={<LandingPage />} />
            <Route path="LandingPage" element={<LandingPage />} />
            <Route path="login" element={<Login />} />
            {REGISTRATION_ENABLED && <Route path="register" element={<Register />} />}
          </Route>
          <Route path="/" element={<LayoutApp />}>
            <Route path="home" element={<Home />} />
            <Route path="dummy" element={<NewDummy />} />
          </Route>
          <Route path="/not_authorized" element={<NotAuthorized />} />
        </Routes>
      </Router>
    </Provider>
  );
}

export default App;