import React, { useEffect, useMemo, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getUserSession } from '../actions/session';
import { selectUserIsLoggedIn, selectSessionLoading } from '../selectors';
import { Container, CssBaseline, Box } from '@mui/material';
import Header from 'components/layout/Header';
import NavBar from 'components/layout/NavBar';
import Footer from 'components/layout/Footer';
import { ThemeProvider } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import getTheme from 'components/layout/theme';

const LayoutApp = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userIsLoggedIn = useSelector(selectUserIsLoggedIn);
  const loading = useSelector(selectSessionLoading);

  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

  const theme = useMemo(() => getTheme(prefersDarkMode), [prefersDarkMode]);

  useEffect(() => {
    const checkUserSession = async () => {
      if (!userIsLoggedIn && !loading) {
        const isAuthorized = await dispatch(getUserSession());
        if (isAuthorized === false) {
          navigate('/not_authorized');
        }
      }
    }

    checkUserSession();
  }, [dispatch, userIsLoggedIn, loading, location.pathname, navigate]);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />

      <NavBar />

      <Container>
        <Box sx={{ mt: 2, mb: 2 }}>
          <Outlet />
        </Box>
      </Container>

    </ThemeProvider>
  );
};

export default LayoutApp;