import React, { useState, useEffect } from 'react';
import { Paper, Typography, Box, Button, Alert, Link as MuiLink, TextField, useMediaQuery } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, Link } from 'react-router-dom';
import { addUser } from "../actions/session";
import { selectUserIsLoggedIn } from '../selectors';
import { paperStyle, formStyle, backLinkStyle } from './Register.styles';

const Register = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [emailState, setEmail] = useState('');
  const [passwordState, setPassword] = useState('');
  const [passwordConfirmationState, setPasswordConfirmation] = useState('');

  const isLoggedIn = useSelector(selectUserIsLoggedIn);

  const isMobile = useMediaQuery('(max-width:600px)');

  useEffect(() => {
    if (isLoggedIn) {
      navigate('/');
    }
  }, [isLoggedIn, navigate]);

  const handleSignUp = async (email, password, passwordConfirmation) => {
    const response = await dispatch(addUser(email, password, passwordConfirmation));
    if (!response.success) {
      setErrorMessage(response.message);
    } else {
      setSuccessMessage("Registration successful! You can now log in.");
      setErrorMessage('');
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    handleSignUp(emailState, passwordState, passwordConfirmationState);
  };

  return (
      <Paper sx={paperStyle(isMobile)}>
      <Typography variant="h4" gutterBottom>Welcome!</Typography>

      {successMessage ? (
        <>
          <Alert severity="success" sx={{ marginBottom: '16px' }}>
            {successMessage}
          </Alert>
          <MuiLink component={Link} to="/login" variant="body2" color="primary">
            Go to Login
          </MuiLink>
        </>
      ) : (
        <>
          <Typography variant="h6" gutterBottom>Register</Typography>

          {errorMessage && (
            <Alert severity="error" sx={{ marginBottom: '16px' }}>
              {errorMessage}
            </Alert>
          )}

          <Box component="form" onSubmit={handleSubmit} sx={formStyle}>
            <TextField
              label="Email"
              type="email"
              value={emailState}
              onChange={(e) => setEmail(e.target.value)}
              fullWidth
              required
            />
            <TextField
              label="Password"
              type="password"
              value={passwordState}
              onChange={(e) => setPassword(e.target.value)}
              fullWidth
              required
            />
            <TextField
              label="Confirm Password"
              type="password"
              value={passwordConfirmationState}
              onChange={(e) => setPasswordConfirmation(e.target.value)}
              fullWidth
              required
            />
            <Button type="submit" variant="contained" color="primary" fullWidth>
              Register
            </Button>
          </Box>

          <MuiLink component={Link} to="/login" variant="body2">
            Already have an account? Log in
          </MuiLink>
        </>
      )}
    </Paper>
  );
};

export default Register;
