import { ApiService } from 'services/apiService';

const AuthService = {
  getUserSession: () => {
    return ApiService.get('/api/v1/me');
  },

  addUser: (email, password, passwordConfirmation) => {
    return ApiService.post('/api/v1/users', {
      user: { email, password, password_confirmation: passwordConfirmation }
    });
  },

  createUserSession: (email, password) => {
    return ApiService.post('/api/v1/sign_in', { user: { email, password } });
  },

  destroyUserSession: () => {
    return ApiService.delete('/api/v1/sign_out');
  }
};

export default AuthService;
