import { createAction } from 'redux-actions';
import AuthService from 'services/authService';
import * as types from '../constants/ActionTypes';

const addUserSession = createAction(types.ADD_USER_SESSION);
const deleteUserSession = createAction(types.DELETE_USER_SESSION);
const setUserSessionLoading = createAction(types.SET_USER_SESSION_LOADING);

const getUserSession = () => {
  return async (dispatch, getState) => {
    const { loading } = getState().session;

    if (loading) return;

    try {
      dispatch(setUserSessionLoading(true));
      const response = await AuthService.getUserSession();
      dispatch(addUserSession(response));
      return true;
    } catch (error) {
      return false;
    }
  };
};

const addUser = (email, password, passwordConfirmation) => {
  return async (dispatch) => {
    try {
      const response = await AuthService.addUser(email, password, passwordConfirmation);
      dispatch(addUserSession(response));
      return { success: true };
    } catch (error) {
      return { success: false, message: 'Sign-up failed. Please try again.' };
    }
  };
};

const createUserSession = (email, password) => {
  return async (dispatch) => {
    try {
      const response = await AuthService.createUserSession(email, password);
      dispatch(addUserSession(response));
      return { success: true };
    } catch (error) {
      return { success: false, message: 'Login failed. Please check your credentials.' };
    }
  };
};
const destroyUserSession = () => {
  return async (dispatch) => {
    try {
      await AuthService.destroyUserSession();
      dispatch(deleteUserSession());
    } catch (error) {
      return { success: false, message: 'Logout failed. Please try again.' };
    }
  };
};

export {
  addUser,
  createUserSession,
  destroyUserSession,
  getUserSession,
};
