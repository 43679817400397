import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { destroyUserSession } from '../../actions/session';
import { selectUserIsLoggedIn } from '../../selectors';
import { AppBar, Toolbar, Typography, Box, Button } from '@mui/material';

const NavBar = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isLoggedIn = useSelector(selectUserIsLoggedIn);
  const handleLogout = () => {
    dispatch(destroyUserSession());
    navigate('/');
  };

  return (
    <AppBar position="static">
      <Toolbar>
        <Typography variant="h6" sx={{ flexGrow: 1 }}>
          Contact Manager
        </Typography>
          {isLoggedIn && (
            <Box>
              <Button
                variant="contained"
                color="primary"
                onClick={() => handleLogout()}
              >
                Logout
              </Button>
            </Box>
          )}
      </Toolbar>
    </AppBar>
  );
};

export default NavBar;
