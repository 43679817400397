import React from 'react';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { destroyUserSession } from '../../actions/session';
import { selectUserIsLoggedIn } from '../../selectors';
import { Typography, Box, Button, Link } from '@mui/material';

const REGISTRATION_ENABLED = process.env.REGISTRATION_ENABLED;

const Header = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isLoggedIn = useSelector(selectUserIsLoggedIn);

  const handleLogout = () => {
    dispatch(destroyUserSession());
    navigate('/');
  };

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '24px 24px 0 24px',
        backgroundColor: 'transparent',
      }}
    >
      <Typography variant="h6" sx={{ paddingLeft: '24px' }}>
        <Link
          component={RouterLink}
          to="/"
          sx={{ textDecoration: 'none', color: 'inherit', cursor: 'pointer' }}
        >
          Contact Manager
        </Link>
      </Typography>

      {!isLoggedIn ? (
        <Box sx={{ display: 'flex', gap: '16px', paddingRight: '24px' }}>
          <Link
            component="button"
            variant="body2"
            onClick={() => navigate('/login')}
            sx={{ textDecoration: 'none', cursor: 'pointer' }}
          >
            Login
          </Link>
          {REGISTRATION_ENABLED && (
            <Button
              variant="contained"
              color="primary"
              onClick={() => navigate('/register')}
            >
              Register
            </Button>
          )}
        </Box>
      ) : (
        <Button
          variant="contained"
          color="primary"
          onClick={handleLogout}
          sx={{ paddingRight: '24px' }}
        >
          Logout
        </Button>
      )}
    </Box>
  );
};

export default Header;
