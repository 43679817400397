import axios from 'axios';

const API_BASE_URL = process.env.API_BASE_URL;

const apiService = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true,
});

export const ApiService = {
  get: async (endpoint) => {
    try {
      const response = await apiService.get(endpoint);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  post: async (endpoint, data) => {
    try {
      const response = await apiService.post(endpoint, data);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  patch: async (endpoint, data) => {
    try {
      const response = await apiService.patch(endpoint, data);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  delete: async (endpoint) => {
    try {
      const response = await apiService.delete(endpoint);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
};