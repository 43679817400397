import {
  ADD_USER_SESSION,
  DELETE_USER_SESSION,
  SET_USER_SESSION_LOADING,
} from '../constants/ActionTypes';

export const initialState = {
    user: {},
    loading: false,
};

const sessionReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_USER_SESSION_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case ADD_USER_SESSION:
      return {
        ...state,
        user: action.payload.user,
        loading: false,
      };

    case DELETE_USER_SESSION:
      return {
        ...state,
        user: null,
        loading: false,
      };

    default:
      return state;
  }
};

export default sessionReducer;
