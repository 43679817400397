import { createTheme } from '@mui/material/styles';

// Define the Basecamp-inspired light and dark themes
const getTheme = (prefersDarkMode) => {
  return createTheme({
    palette: {
      mode: prefersDarkMode ? 'dark' : 'light',
      ...(prefersDarkMode
        ? {}
        : {
            primary: {
              main: '#27364B',
            },
            secondary: {
              main: '#3DA75C',
            },
            background: {
              default: '#ffffff',
              paper: '#ffffff',
            },
            text: {
              primary: '#27364B',
              secondary: '#555555',
            },
            action: {
              active: '#005BFF',
            },
            warning: {
              main: '#FFD300',
            },
          }),
    },
    typography: {
      fontFamily: `'Roboto', 'Helvetica', 'Arial', sans-serif`,
      button: {
        textTransform: 'none',
      },
    },
  });
};

export default getTheme;