import React, { useState, useEffect } from 'react';
import { Paper, Typography, Box, Button, Alert, Link as MuiLink, TextField, useMediaQuery } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, Link } from 'react-router-dom';
import { createUserSession } from "../actions/session";
import { selectUserIsLoggedIn } from '../selectors';
import { paperStyle, formStyle, backLinkStyle } from './Login.styles';

const REGISTRATION_ENABLED = process.env.REGISTRATION_ENABLED;

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [errorMessage, setErrorMessage] = useState('');
  const [emailState, setEmail] = useState('');
  const [passwordState, setPassword] = useState('');

  const isLoggedIn = useSelector(selectUserIsLoggedIn);

  const isMobile = useMediaQuery('(max-width:600px)');

  useEffect(() => {
    if (isLoggedIn) {
      navigate('/home');
    }
  }, [isLoggedIn, navigate]);

  const handleLogin = async (email, password) => {
    const response = await dispatch(createUserSession(email, password));
    if (!response.success) {
      setErrorMessage(response.message);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    handleLogin(emailState, passwordState);
  };

  return (
    <Paper sx={paperStyle(isMobile)}>
      <Typography variant="h4" gutterBottom>Welcome back!</Typography>
      <Typography variant="h6" gutterBottom>Login</Typography>

      {errorMessage && (
        <Alert severity="error" sx={{ marginBottom: '16px' }}>
          {errorMessage}
        </Alert>
      )}

      <Box component="form" onSubmit={handleSubmit} sx={formStyle}>
        <TextField
          label="Email"
          type="email"
          value={emailState}
          onChange={(e) => setEmail(e.target.value)}
          fullWidth
          required
        />
        <TextField
          label="Password"
          type="password"
          value={passwordState}
          onChange={(e) => setPassword(e.target.value)}
          fullWidth
          required
        />
        <Button type="submit" variant="contained" color="primary" fullWidth>
          Sign In
        </Button>
      </Box>

      {REGISTRATION_ENABLED && (
        <Typography variant="body2" gutterBottom>
          Don't have an account? {' '}
          <MuiLink component={Link} to="/register" variant="body2">
            Register
          </MuiLink>
        </Typography>
      )}
    </Paper>
  );
};

export default Login;
