import React from 'react';
import { Link } from 'react-router-dom';

const NotAuthorized = () => {
  return (
    <div style={{ textAlign: 'center', marginTop: '50px' }}>
      <h1>Not Authorized</h1>
      <p>You are not authorized to access this page. Please log in.</p>
      <Link to="/login">Go to Login</Link>
    </div>
  );
};

export default NotAuthorized;
