import { configureStore } from '@reduxjs/toolkit';
import rootReducer from '../reducers/rootReducer';
import { initialState as sessionInitialState } from '../reducers/sessionReducer';

const setupStore = (preloadedState) => {
  const initialState = {
    session: sessionInitialState,
    ...preloadedState
  };

  const store = configureStore({
    reducer: rootReducer,
    preloadedState: initialState,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
    devTools: process.env.NODE_ENV !== 'production',
  });

  return store;
};

export default setupStore;
