import React, { useMemo } from 'react';
import { Outlet } from 'react-router-dom';
import { Container, CssBaseline, Box } from '@mui/material';
import Header from 'components/layout/Header';
import Footer from 'components/layout/Footer';
import { ThemeProvider } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import getTheme from 'components/layout/theme';

const LayoutPublic = () => {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

  const theme = useMemo(() => getTheme(prefersDarkMode), [prefersDarkMode]);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />

      <Header />

      <Container>
        <Box sx={{ mt: 2, mb: 2 }}>
          <Outlet />
        </Box>
      </Container>

      <Footer />
    </ThemeProvider>
  );
};

export default LayoutPublic;